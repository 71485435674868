//
// Dropzone
//

.dropzone-area {

  .dropzone {

    min-height: auto;
    padding: 1.5rem 1.75rem;
    text-align: center;
    cursor: pointer;
    border: 1px dashed #009ef7;
    background-color: #f1faff;
    border-radius: .475rem !important;

    &.dz-clickable {
      cursor: pointer;
    }

    .dz-message {
      margin: 0;
      display: flex;
      text-align: left;

      i {
        margin-top: 15px;
        color: #009ef7;
      }
      div {

        margin-left: 1rem !important;

        h3 {
          line-height: 1.4em;
        }
        span {
          color: #009ef7;
        }
      }
      .ms-hover {
        display: none;
      }

    }

    .dropzone-previews {
      .attachment-item {
        .attachment-item-content {
          .attachment-item-image-wrapper {
            i {
              position: absolute;
              top: -8px;
              right: -6px;
              color: #555;
              border: 1px solid #555;
              -webkit-border-radius: 50% !important;
              -moz-border-radius: 50% !important;
              border-radius: 50% !important;
              padding: 5px;
              background-color: rgba(255,255,255,0.7);

              &:hover {
                background-color: white !important;
                color: red;
                border-color: red;
              }
            }
          }
        }
      }
    }

  }

  &.dz-drag-hover {

    position: relative;

    &:after {
      display: block;
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background-color: transparent;
      z-index: 1000;
    }

    .dropzone {
      background-image: linear-gradient(135deg, #ebebeb 25%, #e0e0e0 25%, #e0e0e0 50%, #ebebeb 50%, #ebebeb 75%, #e0e0e0 75%, #e0e0e0 100%);
      background-size: 14.14px 14.14px;
      border-color: #ccc;

      .dz-message {
        i {
          color: #666;
        }
        div {
          span {
            color: #666;
          }
        }
        .ms-normal {
          display: none;
        }
        .ms-hover {
          display: block;
        }
      }
    }
  }

}
